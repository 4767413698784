import React from 'react';
import Layout from '../../components/Layout';

export default () => (
  <Layout>
    <section className="section hero is-medium">
      <div className=" hero-body">
        <h1 className="title">Thank you for contacting us!</h1>
        <h2 className="subtitle">
          Your message was successfully sent to the administrator. We will get in touch with you.
        </h2>
      </div>
    </section>
  </Layout>
);
